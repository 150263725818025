import { ICarteira } from './reducers/preferenceSlice';

const monthNames = [
  'jan',
  'fev',
  'mar',
  'abr',
  'mai',
  'jun',
  'jul',
  'ago',
  'set',
  'out',
  'nov',
  'dez',
];

export const checkPeriodoPersonalizado = (
  periodo: string,
): { start: string; end: string } | null => {
  const data_split = periodo.split(/[+\s]+/);  // separa o periodo em duas partes(separador: espa�o em branco ou '+')
  if(data_split.length < 2){   // se tiver um ou nenhum elemento, ele vai utilizar a data_ini como o periodo e data_fim como 31129999
    return null;
  }
  return {
    start: data_split[0],
    end: data_split[1],
  };
};

export const roundDecimal = (valueRaw: any | number, numCasas: number) => {
  if (valueRaw === '') return '';
  let value = valueRaw;
  if (typeof valueRaw === 'string') value = parseFloat(valueRaw.replace(',', '.'));
  const valueAux = (Math.round((value + 0.00001) * 100) / 100).toFixed(numCasas);
  return valueAux
    .toString()
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
};

export const extendedNumberFormatter2 = (valueString: string, prefixo: string) => {
  if (valueString === '') return '';
  const usedPrefix = typeof prefixo === 'undefined' ? prefixo : '';
  const value = parseFloat(valueString);
  let divisor = 1;
  let sufixo = '';
  let numCasas = 2;

  if (value >= 999999999) {
    divisor = 1000000000;
    sufixo = ' bi';
  } else if (value >= 999999) {
    divisor = 1000000;
    sufixo = ' mi';
  } else if (value >= 999) {
    divisor = 1000;
    sufixo = ' mil';
  } else {
    numCasas = 0;
  }

  const dividido = value / divisor;

  return usedPrefix + roundDecimal(dividido.toString(), numCasas) + sufixo;
};

export const extendedNumberFormatter = (numToFormat: number, digits?: number, prefix?: string) => {
  let num = numToFormat;
  let negative = false;
  if (numToFormat < 0) {
    num *= -1;
    negative = true;
  }
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: ' k' },
    { value: 1e6, symbol: ' mi' },
    { value: 1e9, symbol: ' bi' },
    { value: 1e12, symbol: ' tr' },
    { value: 1e15, symbol: ' qd' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((newItem) => num >= newItem.value);

  const usePrefix = `${negative ? '-' : ''}${prefix ?? ''}`;

  return item
    ? usePrefix +
        (num / item.value).toFixed(digits).replace(rx, '$1').replace('.', ',') +
        item.symbol
    : '0';
};

export enum FormatTypes {
  percentage = 'percentage',
  decimals = 'decimals',
  no_decimals = 'no_decimals',
  monetary_extense = 'monetary_extense',
  monetary = 'monetary',
  extense = 'extense',
  millions = 'millions',
  month_year = 'month_year',
  text = 'text',
}
export const helperFormat = (
  value: any,
  type: FormatTypes,
  digits?: number,
  moneyPrefix?: string | null,
) => {
  let content = '-';
  if (value === undefined || value === 'nd' || value === '' || Number.isNaN(value)) return content;

  switch (type) {
    case 'percentage':
      content = `${roundDecimal(value, digits ?? 2)}%`;
      break;
    case 'decimals':
      content = roundDecimal(value, digits ?? 2);
      break;
    case 'no_decimals':
      content = roundDecimal(value, digits ?? 0);
      break;
    case 'monetary_extense':
      content = extendedNumberFormatter(value, digits ?? 2, moneyPrefix ?? 'R$');
      break;
    case 'monetary':
      content = `${moneyPrefix ?? 'R$'} ${roundDecimal(value, digits ?? 2)}`;
      break;
    case 'extense':
      content = extendedNumberFormatter(value);
      break;
    case 'millions':
      content = roundDecimal(value, digits ?? 0);
      break;
    case 'month_year':
      content = `(${monthNames[parseFloat(value.substring(6, 8)) - 1]}/${value.substring(1, 5)})`;
      break;
    case 'text':
      content = value;
      break;
    default:
      content = value;
      break;
  }

  return content;
};

export const limitToModulus = (num: number | string, limit: number): number | string => {
  if (typeof num === 'string') return num;
  const sign = Math.sign(num);
  if (sign * num > limit) return sign * limit;
  return num;
};

export function groupAndSumByProperties<T extends Record<string, any>>(
  data: T[],
  classKey: keyof T,
  subclassKey: keyof T,
  sumKeys: (keyof T)[],
) {
  // Group objects by class and subclass
  const groups = data.reduce((acc, curr) => {
    const classValue = curr[classKey];
    const subclassValue = curr[subclassKey];
    const group = acc.findIndex((g) => g.class === classValue && g.subclass === subclassValue);

    if (group !== -1) {
      // If the group already exists, add the numeric properties to the subtotal
      sumKeys.forEach((key: any) => {
        acc[group].subtotal[key] = (acc[group].subtotal[key] || 0) + curr[key];
      });
      acc[group].values.push(curr);
    } else {
      const subtotal: Record<string, number> = {};
      sumKeys.forEach((key: any) => {
        subtotal[key] = curr[key];
      });

      acc.push({
        class: classValue,
        subclass: subclassValue,
        subtotal,
        values: [curr],
      });
    }

    return acc;
  }, [] as { class: string; subclass: string; subtotal: Record<string, number>; values: T[] }[]);

  // Group objects by class and compute the subtotal for each class
  const classGroups = groups.reduce((acc, curr) => {
    const classValue = curr.class;
    const group = acc.findIndex((g) => g.class === classValue);

    if (group !== -1) {
      // If the group already exists, add the numeric properties to the subtotal
      sumKeys.forEach((key: any) => {
        acc[group].subtotal[key] = (acc[group].subtotal[key] || 0) + curr.subtotal[key];
      });
      acc[group].subclasses.push({
        subclass: curr.subclass,
        subtotal: curr.subtotal,
        values: curr.values,
      });
    } else {
      // If the group doesn't exist, create a new group with a subtotal object
      acc.push({
        class: classValue,
        subtotal: structuredClone(curr.subtotal),
        subclasses: [{ subclass: curr.subclass, subtotal: curr.subtotal, values: curr.values }],
      });
    }

    return acc;
  }, [] as { class: string; subtotal: Record<string, number>; subclasses: { subclass: string; subtotal: Record<string, number>; values: T[] }[] }[]);

  return classGroups;
}

export function sumByProperties<T extends Record<string, any>>(data: T[], sumKeys: (keyof T)[]) {
  // Group objects by class and subclass
  const groups = data.reduce((acc, curr) => {
    // If the group already exists, add the numeric properties to the subtotal
    sumKeys.forEach((key: any) => {
      acc[key] = (acc[key] || 0) + curr[key];
    });
    return acc;
  }, {} as any);

  return groups;
}

export const auxFormatPercent = (value: number | string) => {
  if (value === '') return value;
  return typeof value === 'number' ? value * 100 : Number(value) * 100;
};

function verificarCarteira(selectedBenchmarks: string[], benchmarksLiberados: string[]) {
  for (let i = 0; i < selectedBenchmarks.length; i++) {
      if (!benchmarksLiberados.includes(selectedBenchmarks[i])) {
          return true;
      }
  }
  return false;
}

export function selecionarBenchmarks(
  loadedCarteiraList: boolean,
  carteiraList: ICarteira[],
  carteira: string | null,
  dispatchPDF: boolean | undefined,
  selectedBenchmarks: string[] | null,
  nomeAba: string,
  dispatchSelectedBenchmarks: (newBenchmarks: string[], historyReplace?: boolean) => void,
) {
  if (loadedCarteiraList && carteiraList && carteiraList[0] && carteira && !dispatchPDF) {
    if (!selectedBenchmarks || verificarCarteira(
      selectedBenchmarks,
      carteiraList.filter((item) => item.nome_portfolio.toUpperCase() === carteira.toUpperCase())[0]?.CV_bench_liberado
      )) {
      if(nomeAba === 'resumoCart'){
        dispatchSelectedBenchmarks(carteiraList.filter((item) => item.nome_portfolio.toUpperCase() === carteira.toUpperCase())[0]?.CV_bench_carteira1 ?? [], true);
      }else{
        dispatchSelectedBenchmarks(carteiraList.filter((item) => item.nome_portfolio.toUpperCase() === carteira.toUpperCase())[0]?.CV_bench_mes_a_mes ?? [], true);
      }
    } else {
      dispatchSelectedBenchmarks(selectedBenchmarks ? selectedBenchmarks : [''], true);
    }
  }
}

export const compareDatesDDMMYYYY = (date1: string|number, date2: string|number) => {
  if (typeof date1 === 'number') date1 = date1.toString();
  if (typeof date2 === 'number') date2 = date2.toString();
  const parsedDate1 = parseDateDDMMYYYY(date1);
  const parsedDate2 = parseDateDDMMYYYY(date2);

  if (parsedDate1 < parsedDate2){
    return -1;
  }
  else if (parsedDate1 > parsedDate2){
    return 1;
  }
  else {
    return 0;
  }
}

export const parseDateDDMMYYYY = (date: string) => {
  const day = Number(date.slice(0,2));
  const month = Number(date.slice(2,4)) - 1;
  const year = Number(date.slice(4,8));
  return new Date(year, month, day);
}