import React from 'react';

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { useTheme } from 'styled-components';
import useIsMobile from '../../../src/services/useIsMobile';
import { helperFormat, FormatTypes } from '../../../src/services/helper';

interface Props {
  tipo: FormatTypes;
  assets: string[] | null;
  data: any;
  moneyPrefix: string | null;
  handleOnAnimationEnd?: () => void;
}

export default function GraficoArea({ data, tipo, assets, moneyPrefix, handleOnAnimationEnd }: Props) {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload) {
      return (
        <div
          style={{ backgroundColor: theme.backgroundColorSecondary, borderRadius: 5, padding: 10 }}>
          {payload.map((item: any, i: number) => {
            return (
              <div key={Math.random()}>
                <div style={{ fontWeight: 'bold' }}>{i === 0 && item.payload.datesTooltip}</div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div
                    style={{ width: 10, height: 10, backgroundColor: item.stroke, marginRight: 5 }}
                  />
                  <div>{`${
                    item.name === 'Carteira_retorno' ? 'Carteira' : item.name
                  } : ${helperFormat(item.value, tipo, 2, moneyPrefix)}`}</div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  };

  return data && data.length > 0 && assets ? (
    <ResponsiveContainer width="100%" height={300}>
      <AreaChart
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 10,
          bottom: 10,
        }}>
        <CartesianGrid strokeDasharray="1 0" vertical={false} />
        <XAxis dataKey="name" tickLine={false} axisLine={false} tickMargin={15} />
        <YAxis
          width={!isMobile && tipo === 'percentage' ? 45 : 84}
          tickLine={false}
          tickFormatter={(value) => {
            return `${helperFormat(value, tipo, 1, moneyPrefix)}`;
          }}
          axisLine={false}
          domain={[(datamin: number) => Math.min(datamin) * 0.75, 'auto']}
        />
        <Tooltip wrapperStyle={{ outline: 'none' }} content={<CustomTooltip />} />
        <Legend
          formatter={(value) => (
            <span style={{ color: 'black' }}>
              {value === 'Carteira_retorno' ? 'Carteira' : value}
            </span>
          )}
          iconType="square"
          wrapperStyle={{ position: 'relative' }}
        />
        {assets?.map((item: string, i: number) => (
          <Area
            onAnimationEnd ={handleOnAnimationEnd}
            type="monotone"
            key={item}
            dataKey={item}
            stroke={theme.areaChartColors[i % theme.areaChartColors.length]}
            fill={theme.areaChartColors[i % theme.areaChartColors.length]}
            fillOpacity={0.5}
            dot={false}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  ) : null;
}
