import { useEffect, useRef } from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
import { setToken, setLoggedIn} from './reducers/preferenceSlice';
import { useAppDispatch, useAppSelector } from './reduxHooks';

export default function useLoggedIn() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [token, loggedIn] = useAppSelector((state) => [
    state.preference.token,
    state.preference.loggedIn,
  ]);
  const path = location.pathname.substring(1);
  const dataAtual_timezone = new Date().toLocaleString("en-US", {timeZone: "America/Sao_Paulo"});
  const today = new Date(dataAtual_timezone);

  if(path === 'AgendamentoPDF'){  // se for Agendamento, pula o login, o token e expiration são passados como parametro na URL
    const searchParams = new URLSearchParams(location.search);
    const tokenParam = String(searchParams.get('token'));
    const expirationParam = Number(searchParams.get('expiration'));

    const TokenParam = { value: tokenParam, expiration: expirationParam };

    if(tokenParam && expirationParam){  // seta o localStorage com o token
      window.localStorage.setItem(
        `token-${process.env.REACT_APP_NAME ?? 'comdinheiro'}`,
        JSON.stringify(TokenParam),
      );
    }
  }
  useEffect(() => {
    const storage = window.localStorage.getItem(
      `token-${process.env.REACT_APP_NAME ?? 'comdinheiro'}`,
    );
    const { value: sessionToken, expiration: tokenExpiration } = storage
      ? JSON.parse(storage)
      : { value: null, expiration: null };

    if (!token.value && sessionToken && today.getTime() < tokenExpiration) {
      dispatch(setToken({ token: sessionToken, expiration: tokenExpiration }));
      dispatch(setLoggedIn(true));
      return;
    }

    if (token.value && today.getTime() < tokenExpiration) {
      dispatch(setLoggedIn(true));
      return;
    }

    if (
      (token.value && today.getTime() > tokenExpiration) ||
      tokenExpiration === null ||
      !token.value
    ) {
      if (path !== 'login') {
        navigate(
          `/login${path !== '' ? `?&redirect=${path}` : ''}${
            path !== '' && location.search !== '' ? `&${location.search.substring(1)}` : ''
          }`,
        );
      }
    }

    const emptyToken = { token: '', expiration: null };

    dispatch(setToken(emptyToken));
    window.localStorage.setItem(
      `token-${process.env.REACT_APP_NAME ?? 'comdinheiro'}`,
      JSON.stringify(emptyToken),
    );
  }, [token]);

  // Utilizar os dados mais recentes quando o evento 'popstate' for disparado
  const loggedInRef = useRef(loggedIn);
  const pathRef = useRef(path);
  const locationRef = useRef(location);
  useEffect(() => {
    loggedInRef.current = loggedIn;
  }, [loggedIn]);
  useEffect(() => {
    pathRef.current = path;
  }, [path]);
  useEffect(() => {
    locationRef.current = location;
  }, [location]);
  /**
  * Voltar para a tela de login caso o usuário utilize o botão de voltar do navegador e não estiver logado
  */
  const verificarLoggedIn = () => {
      if (!loggedInRef.current && pathRef.current !== 'login') {
        navigate(
          `/login${pathRef.current !== '' ? `?&redirect=${pathRef.current}` : ''}${
            pathRef.current !== '' && locationRef.current.search !== '' ? `&${locationRef.current.search.substring(1)}` : ''
          }`,
        );
      }
  };

  /**
   * Adiciona eventlistener para verificar login quando voltar/avançar a página no navegador
   */
  useEffect(() => {
    window.addEventListener('popstate', verificarLoggedIn);
    return () => {
      window.removeEventListener('popstate', verificarLoggedIn);
    };
  }, []);

  return loggedIn;
}
